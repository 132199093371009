import React from "react";
import "./footer.scss";
import {FooterProps} from "../../../../shared/interfaces";
import {Paragraph} from "../Paragraph/Paragraph";
import {useTranslation} from "react-i18next";
import {I18NParagraph} from "../I18NParagraph/I18NParagraph";
import {Link} from "../Link/Link";

export const Footer : React.FC<FooterProps> =  (props) => {
    const {t} = useTranslation();
    
    let meBaseUrl = props.meBaseUrl ?? "";

    return (
        <footer>
            <div className="main-container-footer">
                <hr/>
                <div className="content-container-footer">
                    <div className="footer-item">
                        <Link underline={true} link={t('global.footer.imprint.link', {companyDomain: props.companyName})} openInNewTab={true} text={t('global.footer.imprint.label')}/>
                    </div>
                    <div className="footer-item">
                        <Link underline={true} link={t('global.footer.privacystatement.link', {companyDomain: props.companyName})} openInNewTab={true} text={t('global.footer.privacystatement.label')}/>
                    </div>
                    <div className="footer-item">
                        <Link underline={true} link={meBaseUrl + "/termsofuse"} openInNewTab={true} text={t('global.footer.termsofuse.label')}/>
                    </div>
                    {props.showGenderInfo &&
                    <div className="footer-item">
                        <Link underline={true} link={meBaseUrl + "/gender-hinweis"} openInNewTab={true} text={t('global.footer.genderinfo.label')}/>
                    </div>
                    }
                    <div className="footer-item">
                        <a href={meBaseUrl + "/cp"}>
                            {t('global.footer.portal.label')}
                        </a>
                    </div>
                </div>
                <div id="copyright" className="footer-item">
                    <I18NParagraph i18n='global.footer.copyrightnote' values={{year : new Date().getFullYear()}}/>
                </div>
                <div id="love" className="footer-item">
                    <I18NParagraph i18n={"global.footer.note.1"}/>
                    <Paragraph id="footer-emoji" text={"❤️"}/>
                    <I18NParagraph i18n={"global.footer.note.2"}/>
                </div>
                {props.version &&
                    <Paragraph id="versionNumber" text={props.version} />
                }
            </div>
        </footer>
    );
};
