import React, { useState } from "react";
import "./navigation.scss";
import { NavigationProps } from "../../../../shared/interfaces";
import { useTranslation } from "react-i18next";
import { Paragraph } from "../Paragraph/Paragraph";
import { NavigationEntry } from "./NavigationEntry";
import { I18NHeading } from "../I18NHeading/I18NHeading";
import i18next from "i18next";
import { UntitledColorizeIcon, UntitledIcon } from "../icons";
import { Button } from "../Button/Button";
import { useNavigate } from "react-router";
import { Spacing } from "../Spacing";
import { useActiveTheme } from "../Redesign/activeTheme";
import { Toggle } from "../Toggle/Toggle";

/**
 * Primary UI component for user interaction
 */
export const Navigation: React.FC<NavigationProps> = (props) => {
  const { t } = useTranslation();
  const { setTheme, activeTheme } = useActiveTheme();

  const [collapseContentVisible, setCollapseContentVisible] = useState(false);
  const [, setCurrentLang] = useState("de");

  const changeLanguage = () => {
    // @ts-ignore
    let lang = i18next.resolvedLanguage?.includes("de") ? "en" : "de";

    // @ts-ignore
    i18next.changeLanguage(lang, (err, t) => {
      setCurrentLang(lang);
      if (err) return console.log("something went wrong loading", err);
    });
  };

  const toggleCollapse = () => {
    collapseContentVisible
      ? setCollapseContentVisible(false)
      : setCollapseContentVisible(true);
  };

  const combineMenuEntries = () => {
    let navigationEntries = props.entries.map((entry) => {
      if (entry.id === "navigation-logout") {
        return (
          <Button
            id={entry.id}
            key={entry.id}
            onClick={entry.onClick}
            label={t(entry.i18nLabel)}
          />
        );
      }
      return (
        <NavigationEntry
          key={entry.id}
          id={entry.id}
          i18nLabel={entry.i18nLabel}
          icon={entry.icon}
          onClick={entry.onClick}
          i18nNote={entry.i18nNote}
        />
      );
    });

    return (
      <>
        <NavigationEntry
          id={"navigation-theme-switch"}
          i18nLabel={"navigation.changeTheme.label"}
          onClick={() => {}}
        >
          <Toggle
            id={"navigation-theme-switch-toggle"}
            toggleState={activeTheme !== "old"}
            setToggleState={(isNew) => setTheme(!isNew)}
          />
        </NavigationEntry>

        <NavigationEntry
          id={"navigation-language"}
          icon={"globe-01"}
          i18nLabel={t("navigation.changeLang.label")}
          onClick={changeLanguage}
        />

        {navigationEntries}
      </>
    );
  };

  const getUpperInformation = () => {
    if (collapseContentVisible) {
      // in open state
      if (props.username && props.muid) {
        return (
          <div className="navigation-welcome">
            <Paragraph className="strong name" text={props.username} />
            <Paragraph className="mail" text={props.muid} />
          </div>
        );
      } else {
        return (
          <div className={"navigation-welcome"}>
            <I18NHeading type={"h4"} i18n={"navigation.welcome"} />
          </div>
        );
      }
    } else {
      // in closed state
      return (
        <div className={"navigation-menu-heading"}>
          {/* <I18NHeading type={"h4"} i18n={"navigation.menu"} /> */}
        </div>
      );
    }
  };

  const navigate = useNavigate();
  const handleBackArrow = () => navigate("/cp/fidoCredentials");

  return (
    <nav
      role="navigation"
      aria-label={"Navigation"}
      className={collapseContentVisible ? "active navigation" : ""}
    >
      <Spacing fullWidthChildren={false} alignItems="center" gap="16">
        <button
          className="storybook-icon-button"
          onClick={handleBackArrow}
          style={{ cursor: "pointer" }}
        >
          {props?.subpage ? (
            <UntitledColorizeIcon
              style={{
                fill: "var(--button-label-color)",
                color: "var(--button-label-color)",
              }}
              name="real-arrow"
              role="img"
            />
          ) : (
            <UntitledIcon name="logo" viewBox="0 0 32 16" />
          )}
        </button>
        {props.title && (
          <span className="navigation-title">{t(props.title)}</span>
        )}
      </Spacing>
      <div className="navigation-wrapper">
        <div className="navigation-header" onClick={toggleCollapse}>
          {collapseContentVisible ? (
            <UntitledColorizeIcon
              id="loginCollapse"
              className="toggle-container"
              style={{ marginRight: "16px", height: "50px" }}
              name="cross"
            />
          ) : (
            <UntitledColorizeIcon
              id="loginCollapse"
              className="toggle-container"
              name="burger-menu"
            />
          )}
        </div>
        <>
          {getUpperInformation()}
          {collapseContentVisible && (
            <div className="navigation-content">{combineMenuEntries()}</div>
          )}
        </>
      </div>
    </nav>
  );
};
